import { Button, Modal } from 'react-bootstrap';
import './SummaryData.css';
import CloseIcon from '@mui/icons-material/Close';
import { convertPrice, convertToDate } from '../../common/Utilities';
import Spinner from '../UI/Spinner/Spinner';

export const SummaryData = ({
    showModal,
    setShowModal,
    empBody,
    onHandleSubmit,
    empData,
    gpaBaseSumInsured,
    gpaTopUp,
    gpaFinalSumInsuredCoverage,
    loading,
    numOfSelectedParent,
    parentalAdditionCost,
    gtlSumInsured,
    enrolmentDeadline,
    gtlTopUp
}) => {
    return (
        <Modal
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}
            show={showModal}
            onHide={() => {}}
        >
            <div className='upload-container'>
                <div className='upload-header'>
                    <span className='header-text'>Your final enrolment summary:</span>
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)}>
                        <CloseIcon color={'#959FBE'} />
                    </div>
                </div>
                <div className='upload-body-container'>
                    <div className='emp-row-container'>
                        <LabelText label='Employee Name' text={empBody?.name} bold={true} />
                        <LabelText label='Employee Gender' text={empBody?.gender} bold={true} />
                        <LabelText label='Employee DOB' text={convertToDate(empBody?.dob, 'DD/MM/YYYY')} />
                    </div>

                    <h6>{`Dependent selections:`}</h6>
                    <br />

                    <div className='emp-row-container'>
                        <LabelText label='Spouse / Partner Name' text={empBody?.spouseName} />
                        <LabelText label='Spouse / Partner Gender' text={empBody?.spouseGender} />
                        <LabelText
                            label='Spouse / Partner DOB'
                            text={convertToDate(empBody?.spouseDob, 'DD/MM/YYYY')}
                        />
                    </div>

                    {empBody?.childName1 && (
                        <div className='emp-row-container'>
                            <LabelText label='Child 1 Name' text={empBody?.childName1} />
                            <LabelText label='Child 1 Gender' text={empBody?.childGender1} />
                            <LabelText label='Child 1 DOB' text={convertToDate(empBody?.childDob1, 'DD/MM/YYYY')} />
                        </div>
                    )}
                    {empBody?.childName2 && (
                        <div className='emp-row-container'>
                            <LabelText label='Child 2 Name' text={empBody?.childName2} />
                            <LabelText label='Child 2 Gender' text={empBody?.childGender2} />
                            <LabelText label='Child 2 DOB' text={convertToDate(empBody?.childDob2, 'DD/MM/YYYY')} />
                        </div>
                    )}
                    {empBody?.childName3 && (
                        <div className='emp-row-container'>
                            <LabelText label='Child 3 Name' text={empBody?.childName3} />
                            <LabelText label='Child 3 Gender' text={empBody?.childGender3} />
                            <LabelText label='Child 3 DOB' text={convertToDate(empBody?.childDob3, 'DD/MM/YYYY')} />
                        </div>
                    )}
                    {empBody?.parentName1 && (
                        <div className='emp-row-container'>
                            <LabelText label='Parent 1 Name' text={empBody?.parentName1} />
                            <LabelText label='Parent 1 Gender' text={empBody?.parentGender1} />
                            <LabelText label='Parent 1 DOB' text={convertToDate(empBody?.parentDob1, 'DD/MM/YYYY')} />
                        </div>
                    )}
                    {empBody?.parentName2 && (
                        <div className='emp-row-container'>
                            <LabelText label='Parent 2 Name' text={empBody?.parentName2} />
                            <LabelText label='Parent 2 Gender' text={empBody?.parentGender2} />
                            <LabelText label='Parent 2 DOB' text={convertToDate(empBody?.parentDob2, 'DD/MM/YYYY')} />
                        </div>
                    )}
                    <h6>{`Addition / Top-up summary:`}</h6>
                    <div className='inpu-box-step4'>
                        {/* heading */}
                        <div className='row summary-table-title'>
                            <div className='col-md-3'>Coverage</div>
                            <div className='col-md-3'>Sum Insured</div>
                            <div className='col-md-3'>Cost (Incl GST)</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>GMC - Base</div>
                            <div className='col-md-3 summary-table-field'>{`₹ ${parseInt(
                                empBody.sumInsured
                            )?.toLocaleString('en-In')}`}</div>
                            <div className='col-md-3 summary-table-field'>
                                {parentalAdditionCost ? `₹ ${parentalAdditionCost}` : '-'}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>GMC - Top Up</div>
                            <div className='col-md-3 summary-table-field'>{`${convertPrice(
                                empBody?.topupAmount
                            )}`}</div>
                            <div className='col-md-3 summary-table-field'>
                                ₹ {`${parseInt(empBody?.gmcTopupAmount)?.toLocaleString('en-In')}`}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-3'>GPA - Base</div>
                            <div className='col-md-3 summary-table-field'>{`₹ ${parseInt(
                                gpaBaseSumInsured
                            )?.toLocaleString()}`}</div>
                            <div className='col-md-3 summary-table-field'>-</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>GPA - Top Up</div>
                            <div className='col-md-3 summary-table-field'>{`${
                                gpaTopUp ? '₹ ' + parseInt(gpaTopUp)?.toLocaleString('en-In') : '-'
                            }`}</div>
                            <div className='col-md-3 summary-table-field'>
                                {`${
                                    empBody?.gpaTopupPremium
                                        ? '₹ ' + parseInt(empBody?.gpaTopupPremium)?.toLocaleString('en-In')
                                        : '-'
                                }`}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>GTL - Base</div>
                            <div className='col-md-3 summary-table-field'>{`₹ ${parseInt(gtlSumInsured)?.toLocaleString(
                                'en-In'
                            )}`}</div>
                            <div className='col-md-3 summary-table-field'>-</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>GTL - Top Up</div>
                            <div className='col-md-3 summary-table-field'>{`${
                                gtlTopUp ? '₹ ' + parseInt(gtlTopUp)?.toLocaleString('en-In') : '-'
                            }`}</div>
                            <div className='col-md-3 summary-table-field'>
                                {`${
                                    empBody?.gtlTopupPremium
                                        ? '₹ ' + parseInt(empBody?.gtlTopupPremium)?.toLocaleString('en-In')
                                        : '-'
                                }`}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-3'>OPD</div>
                            <div className='col-md-3 summary-table-field'>{`${
                                empBody?.opdValue ? '₹ ' + parseInt(empBody?.opdValue)?.toLocaleString('en-In') : '-'
                            }`}</div>
                            <div className='col-md-3 summary-table-field'>
                                {`${empBody?.opdPremium ? '₹ ' + empBody?.opdPremium : '-'}`}
                            </div>
                        </div>
                    </div>

                    <div>
                        <span className='summary-table-title'>
                            Final amount to be deducted from salary in two installments:{' '}
                        </span>

                        <span className='summary-table-field'>
                            ₹
                            {parseInt(
                                empBody?.parentalAdditionCost +
                                    empBody?.gmcTopupAmount +
                                    empBody?.gpaTopupPremium +
                                    empBody?.gtlTopupPremium +
                                    empBody?.opdPremium
                            )?.toLocaleString('en-In')}
                            (incl GST)
                        </span>
                    </div>
                </div>

                <div className='upload-footer'>
                    <span style={{ padding: 10 }}>
                        {`I confirm the above selections and approve the salary deduction. 
                        The deduction will be taken in two equal installments. You can expect email confirmation of your selections and premium payable within next 24 hours.`}
                    </span>
                    <Button onClick={onHandleSubmit}>Submit</Button>
                </div>

                {loading && (
                    <div className='spinner-container'>
                        <Spinner />
                    </div>
                )}
            </div>
        </Modal>
    );
};

const LabelText = ({ label, text, bold = false, operation }) => {
    return (
        <div className='label-text-container'>
            <span className='emp-label-text'>{label}</span>
            <span className={`${bold ? 'emp-bold-text' : 'emp-normal-text'} text-capitalize`}>{text}</span>
        </div>
    );
};
